<template>
  <button
    class="amp"
    :class="`amp--${region}`"
    @click.prevent="$emit('set-pref', pref)"
  >
    {{ pref.short_name }}
  </button>
</template>

<script>
export default {
  props: {
    pref: {
      type: Object,
      default() {
        return {
          name: "",
          search_cities_path: "",
          search_lines_path: "",
          short_name: ""
        };
      }
    },
    region: {
      type: String,
      default: "",
      required: true,
      validator(val) {
        return (
          [
            "hokkaido",
            "tohoku",
            "koshinetsu",
            "kanto",
            "chubu",
            "kansai",
            "chugoku",
            "shikoku",
            "kyushu",
            "okinawa"
          ].indexOf(val) !== -1
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$region-color: (
  hokkaido: #00a9d3,
  tohoku: #3f99b4,
  koshinetsu: #a4ce7f,
  kanto: #27c0a7,
  chubu: #a4ce7f,
  kansai: #e0a070,
  chugoku: #d66490,
  shikoku: #ac707b,
  kyushu: #e899c5,
  okinawa: #7b8abe
);

.amp {
  width: 5.5em;
  appearance: none;
  outline: none;
  padding: 0.5em 0;
  font-weight: bolder;
  text-align: center;
  background-color: #fff;

  @each $region, $color in $region-color {
    &--#{$region} {
      color: $color;
      border: 1px solid $color;

      &:hover {
        color: #fff;
        background-color: $color;
        transition: all 0.3s;
      }
    }
  }
}
</style>
