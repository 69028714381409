import { render, staticRenderFns } from "./AreaList.vue?vue&type=template&id=72606624&scoped=true&"
import script from "./AreaList.vue?vue&type=script&lang=js&"
export * from "./AreaList.vue?vue&type=script&lang=js&"
import style0 from "./AreaList.vue?vue&type=style&index=0&id=72606624&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72606624",
  null
  
)

export default component.exports