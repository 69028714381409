import axios from 'axios'
import Qs from 'qs'

const apiSuccess = response => {
  if (response.status.toString().match(/20\d$/)) {
    return Promise.resolve(response.data)
  } else {
    return Promise.reject(response.data.message)
  }
};

const apiError = error => {
  if (error.response.status === 401) {
    window.location.href = "/login"
    return Promise.reject(error)
  }
  if ([400, 404, 422].includes(error.response.status)) {
    return Promise.reject(error.response.data)
  }
  return Promise.reject(error.response);
}

const setToken = (params) => {
  const tags = document.getElementsByTagName('meta')
  for (let i = 0; i < tags.length; i++) {
    if (tags[i].name === 'csrf-token') {
      params.authenticity_token = tags[i].content;
      break;
    }
  }
  return params;
}

const paramsSerializer = (params) => {
  return Qs.stringify(params, { arrayFormat: 'brackets' })
}

export default {
  get: (path, params = {}) => {
    return axios.get(path, { params, paramsSerializer }).then(apiSuccess).catch(apiError)
  },
  datapost: (path, params = {}) => {
    console.log(path)
    console.log(params)
    return axios.post(path, params, {
      headers: {
        'X-CSRF-Token': setToken(params).authenticity_token,
        'Content-Type': 'multipart/form-data'
      }
    }).then(apiSuccess).catch(apiError)
  },
  post: (path, params = {}) => {
    return axios.post(path, params, { headers: { 'X-CSRF-Token': setToken(params).authenticity_token } }).then(apiSuccess).catch(apiError)
  },
  jsonPost: (path, params = {}) => {
    return axios.post(path, params, {
      headers: {
        'X-CSRF-Token': setToken(params).authenticity_token
      }
    }).then(apiSuccess).catch(apiError)
  },
  put: (path, params = {}) => {
    return axios.put(path, setToken(params)).then(apiSuccess).catch(apiError)
  },
  delete: (path, params = {}) => {
    return axios.delete(path, { params: setToken(params) }).then(apiSuccess).catch(apiError)
  }
}
