<template>
  <div class="al" :class="classObject">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: ""
    },
    available: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classObject() {
      return {
        [`al--${this.color}`]: this.color,
        "al--disabled": !this.available
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../stylesheets/common/_common";

.al {
  padding: 0.3rem;
  color: #fff;
  font-weight: bolder;
  text-align: center;

  @each $key, $color in $theme-colors {
    &--#{$key} {
      background-color: $color;
    }
  }

  &--disabled {
    background-color: #bfbfbf;
  }
}
</style>
