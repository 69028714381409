<template>
  <a :href="href" class="app-button-request abr">
    <i class="fas fa-comments abr__icon"></i>
    <div class="abr__lead">
      <h5 class="abr__title">物件リクエスト</h5>
      <div>
        条件に見合った物件情報を提携している<br />不動産会社からご紹介させていただきます。
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: "#"
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../stylesheets/common/_common";

.app-button-request.abr {
  display: inline-flex;
  align-items: center;
  padding: 1rem;
  border: solid 3px map-get($theme-colors, ash);
  text-align: left;

  &:hover {
    text-decoration: none;
    background-color: map-get($theme-colors, ash);
  }

  $block: ".abr";

  #{$block} {
    &__icon {
      font-size: 40px;
    }

    &__title {
      font-weight: bolder;
    }

    &__lead {
      margin-left: 1rem;
      color: map-get($theme-colors, ink);
    }
  }
}
</style>
