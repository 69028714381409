<template>
  <button class="bf" :class="classObject" :disabled="disabled">
    <i class="fas fa-star" :class="{ 'text-accent': !active }"></i>
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    block: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classObject() {
      return {
        "bf--block": this.block,
        "bf--active": this.active
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../stylesheets/common/_common";

.bf {
  border: 1px solid #f2bb40;
  border-radius: 5px;
  padding: 0.6em 1em;
  background-color: #fff6e2;
  font-weight: bolder;
  text-align: center;

  &--block {
    width: 100%;
    display: block;
    white-space: nowrap;
  }

  &--active {
    background-color: #f2bb40;
    color: #fff;
  }
}
</style>
