<template>
  <div v-if="visibleRoom" class="rs" :class="{ 'rs--selected': room.selected }">
    <div class="rs__inner">
      <label class="rs__name">
        <input
          type="checkbox"
          v-if="checkable"
          v-model="localRoom.selected"
          :disabled="disable"
        />
        <a :href="'/rooms/' + room.id + params">{{ room.display_room_name }}</a>
      </label>

      <div class="rs__detail">
        <div class="rs__head">
          <ul class="rs__pics">
            <li v-if="room.room_photo1_visible == 'Public'">
              <img
                :src="mansion.room_photo1.url"
                alt="動的にテキストを入れる"
                class="rs__pic"
              />
            </li>
            <li v-if="room.room_photo2_visible == 'Public'">
              <img
                :src="mansion.room_photo2.url"
                alt="動的にテキストを入れる"
                class="rs__pic"
              />
            </li>
            <li v-if="room.room_photo1_visible == 'Private' && room.room_photo2_visible == 'Private'">
              <img
                src="/images/monthly/common/img_no_image_yoko.jpg"
                alt="動的にテキストを入れる"
                class="rs__pic"
              />
            </li>
          </ul>

          <div class="rs__info">
            <ul>
              <li>
                <div class="rs__label">間取り</div>
                <div class="rs__val">
                  {{ room.layout }}
                  <span v-if="loftText">（{{ loftText }}）</span>
                </div>
              </li>
              <li>
                <div class="rs__label">広さ</div>
                <div class="rs__val">{{ room.room_size }}㎡</div>
              </li>
              <li>
                <div class="rs__label">部屋階数</div>
                <div class="rs__val">{{ room.floor }}階</div>
              </li>
              <li>
                <div class="rs__label">入居可能</div>
                <div class="rs__val">{{ room.maximum_occupants }}人</div>
              </li>
              <li>
                <div class="rs__label">ベッド</div>
                <div class="rs__val">{{ room.bed_type }}</div>
              </li>
            </ul>
          </div>
        </div>

        <div class="rs__body">
          <div>
            <h4 class="rs__title">設備</h4>
            <div class="rs__filters">
              <state-label
                color="base"
                :available="room.other_equipment.internet"
                >インターネット対応</state-label
              >
              <state-label
                color="base"
                :available="room.other_equipment.autolock"
                >オートロック</state-label
              >
              <state-label
                color="base"
                :available="
                  room.bath_and_toilet_utensils.bath_and_toilet_separated
                "
                >バス・トイレ別</state-label
              >
              <state-label color="base" :available="room.furniture.closet"
                >クローゼット</state-label
              >
              <state-label
                color="base"
                :available="room.bath_and_toilet_utensils.washstand_independent"
                >洗面所独立</state-label
              >
              <state-label
                color="base"
                :available="room.other_equipment.delivery_box"
                >宅配ボックス</state-label
              >
              <state-label
                color="base"
                :available="room.other_equipment.elevator"
                >エレベーター</state-label
              >
              <state-label
                color="base"
                :available="room.other_equipment.no_smoking_room"
                >禁煙ルーム</state-label
              >
              <state-label
                color="base"
                :available="room.bath_and_toilet_utensils.wash_toilet_seat"
                >洗浄機能便座</state-label
              >
            </div>
          </div>

          <div>
            <h4 class="rs__title">入居条件</h4>
            <div class="rs__filters">
              <state-label
                color="mint"
                :available="room.move_in_conditions.foreigner"
                >外国人対応</state-label
              >
              <state-label
                color="mint"
                :available="room.move_in_conditions.campaign"
                >キャンペーン対応</state-label
              >
              <state-label
                color="mint"
                :available="room.move_in_conditions.woman"
                >女性限定</state-label
              >
            </div>
          </div>

          <div>
            <h4 class="rs__title">家電</h4>
            <div class="rs__filters">
              <state-label
                color="beige"
                :available="room.appliances.refrigerator"
                >冷蔵庫</state-label
              >
              <state-label color="beige" :available="room.appliances.aircon"
                >エアコン</state-label
              >
              <state-label
                color="beige"
                :available="room.appliances.washing_machine"
                >洗濯機</state-label
              >
            </div>
          </div>

          <div>
            <h4 class="rs__title">立地</h4>
            <div class="rs__filters">
              <state-label
                color="peach"
                :available="room.location_conditions.near_coin_parking"
                >コインパーキング至近</state-label
              >
              <state-label
                color="peach"
                :available="room.location_conditions.near_convini"
                >コンビニ至近</state-label
              >
              <state-label
                color="peach"
                :available="room.location_conditions.near_coin_laundry"
                >コインランドリー至近</state-label
              >
            </div>
          </div>

          <h4 class="rs__title">賃料</h4>
          <div class="rs__cost mb-0" v-show="viewMode.pc">
            <dl>
              <dt>期間</dt>
              <dt>賃料</dt>
              <dt>光熱費</dt>
              <dt>クリーニング</dt>
            </dl>
          </div>
          <div class="rs__cost">
            <dl>
              <dt>スーパーショート<br />(〜1ヶ月未満)</dt>
              <dd v-show="viewMode.sp">賃料</dd>
              <dd>
                {{ calcRentSS(room.ss_total_rent, room.contract_ss) }}
              </dd>
              <dd v-show="viewMode.sp">光熱費</dd>
              <dd>
                {{
                  calcUtilitySS(
                    room.ss_utility_cost_difference,
                    room.contract_ss
                  )
                }}
              </dd>
              <dd v-show="viewMode.sp">クリーニング</dd>
              <dd>
                {{ calcCleaning(room.ss_cleaning_cost, room.contract_ss) }}
              </dd>
            </dl>
            <dl>
              <dt>ショート<br />(1ヶ月以上〜3ヶ月未満)</dt>
              <dd v-show="viewMode.sp">賃料</dd>
              <dd>
                {{ calcRent(room.short_total_rent, room.contract_short) }}
              </dd>
              <dd v-show="viewMode.sp">光熱費</dd>
              <dd>{{ calcUtility(0, room.contract_short) }}</dd>
              <dd v-show="viewMode.sp">クリーニング</dd>
              <dd>
                {{
                  calcCleaning(room.basic_cleaning_cost, room.contract_short)
                }}
              </dd>
            </dl>
            <dl>
              <dt>ミドル<br />(3ヶ月〜7ヶ月未満)</dt>
              <dd v-show="viewMode.sp">賃料</dd>
              <dd>
                {{ calcRent(room.middle_total_rent, room.contract_middle) }}
              </dd>
              <dd v-show="viewMode.sp">光熱費</dd>
              <dd>
                {{
                  calcUtility(
                    room.middle_utility_cost_difference,
                    room.contract_middle
                  )
                }}
              </dd>
              <dd v-show="viewMode.sp">クリーニング</dd>
              <dd>
                {{
                  calcCleaning(room.middle_cleaning_cost, room.contract_middle)
                }}
              </dd>
            </dl>
            <dl>
              <dt>ロング<br />(7ヶ月以上〜1２ヶ月未満)</dt>
              <dd v-show="viewMode.sp">賃料</dd>
              <dd>{{ calcRent(room.long_total_rent, room.contract_long) }}</dd>
              <dd v-show="viewMode.sp">光熱費</dd>
              <dd>
                {{
                  calcUtility(
                    room.long_utility_cost_difference,
                    room.contract_long
                  )
                }}
              </dd>
              <dd v-show="viewMode.sp">クリーニング</dd>
              <dd>
                {{ calcCleaning(room.long_cleaning_cost, room.contract_long) }}
              </dd>
            </dl>
          </div>

          <div class="rs__inquiry">
            <div class="rs__tools">
              <button-fav
                @click.native="removeFavorite()"
                :block="true"
                :active="true"
                v-if="removeFav"
                >お気に入りから削除</button-fav
              >
              <button-fav :block="true" :disabled="true" v-else-if="isFaved"
                >お気に入りに追加済み</button-fav
              >
              <button-fav @click.native="addFavorite()" :block="true" v-else
                >お気に入りに追加</button-fav
              >
              <button-contact :block="true" :link="contactURL"
                >お問い合わせ（無料）</button-contact
              >
            </div>
            <div class="rs__company" v-if="operatingCompany">
              <div>運営不動産会社：{{ operatingCompany.name }}</div>
              <div>
                <span class="rs__company-number">
                  {{ operatingCompany.tel }}
                </span>
                <span>
                  {{ operatingCompany.tel2 ? operatingCompany.tel2 : "" }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StateLabel from "../common/AppStateLabel";
import ButtonFav from "../common/AppButtonFav";
import ButtonContact from "../common/AppButtonContact";
import mqMixin from "../../mixins/mq";
import favAPI from "../../api/favorite";

export default {
  data() {
    return {
      visibleRoom: true
    };
  },
  mixins: [mqMixin],
  components: {
    StateLabel,
    ButtonFav,
    ButtonContact
  },
  props: {
    room: {
      type: Object
    },
    mansion: {
      type: Object
    },
    operatingCompany: {
      type: Object
    },
    disable: {
      type: Boolean,
      default: false
    },
    contactPath: {
      type: String,
      default: ""
    },
    removeFav: {
      type: Boolean,
      default: false
    },
    checkable: {
      type: Boolean,
      default: true
    },
    favoritedRooms: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    calcRent(fee, contract_type) {
      if (fee == null || contract_type == false) {
        return "非対応";
      } else {
        return `${Math.round(
          fee / 30
        ).toLocaleString()}円/日 ${fee.toLocaleString()}円/月`;
      }
    },
    calcRentSS(fee, contract_type) {
      if (fee == null || contract_type == false) {
        return "非対応";
      } else {
        return `${Math.round(fee / 30).toLocaleString()}円/日`;
      }
    },
    calcUtility(difference, contract_type) {
      if (difference == null || contract_type == false) {
        return "非対応";
      } else {
        let utility_cost = this.room.basic_monthly_utility_cost + difference;
        return `${utility_cost.toLocaleString()}円/日 ${(
          utility_cost * 30
        ).toLocaleString()}円/月`;
      }
    },
    calcUtilitySS(difference, contract_type) {
      if (difference == null || contract_type == false) {
        return "非対応";
      } else {
        let utility_cost = this.room.basic_monthly_utility_cost + difference;
        return `${utility_cost.toLocaleString()}円/日`;
      }
    },
    calcCleaning(fee, contract_type) {
      if (fee == null || contract_type == false) {
        return "非対応";
      } else {
        return `${fee.toLocaleString()}円/回(${
          this.room.cleaning_cost_payment_type
        })`;
      }
    },
    async addFavorite() {
      await favAPI
        .create({ room_ids: [this.room.id] })
        .then((res) => {
          alert("お気に入り追加しました");
          this.$emit("add-favorite");
        })
        .catch((e) => {
          alert(e["error_message"]);
          return;
        });
    },
    async removeFavorite() {
      await favAPI
        .bulk_delete({ room_ids: [this.room.id] })
        .then((res) => {
          alert("お気に入りから削除しました");
        })
        .catch(() => {
          alert("エラーが発生しました。");
          return;
        });

      location.reload();
    }
  },
  computed: {
    params() {
      return window.location.search;
    },
    localRoom: {
      get() {
        return this.room;
      },
      set(newVal) {
        this.$emit("update:room", newVal);
      }
    },
    loftText() {
      if (this.room.loft.toLowerCase() === "have") return "ロフト付";
      return "";
    },
    contactURL() {
      return `${this.contactPath}?room_ids[]=${this.room.id}`;
    },
    isFaved() {
      return this.favoritedRooms.indexOf(this.room.id) !== -1;
    }
  },
  created() {
    if (this.room.room_visible == "非公開") {
      this.visibleRoom = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../stylesheets/common/_common";

.rs {
  border-bottom: 1px solid map-get($theme-colors, ash-darker);

  &--selected {
    background-color: map-get($theme-colors, sky);
  }

  &__inner {
    padding: 1.8rem;
  }

  &__title {
    font-size: 1rem;
    font-weight: bolder;
  }

  &__head {
    display: flex;

    @include for-mobile {
      flex-wrap: wrap;
    }
  }

  &__pics {
    display: flex;
    width: 60%;
    max-width: 100%;
    justify-content: space-between;
    list-style: none;
    padding-left: 0;

    & > li {
      width: 48%;
    }

    @include for-mobile {
      width: 100%;
    }
  }

  &__pic {
    width: 100%;
    height: 150px;
    object-fit: contain;
  }

  &__cost {
    width: 100%;
    display: table;
    table-layout: fixed;
    border: 1px solid map-get($theme-colors, ash-darker);
    border-collapse: collapse;
    margin-bottom: 1.5rem;

    @include for-mobile {
      display: block;
    }

    dl {
      display: table-row;

      @include for-mobile {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 0;
      }
    }

    dt {
      display: table-cell;
      padding: 0.5rem;
      margin: 0;
      background-color: map-get($theme-colors, ash);
      border: 1px solid map-get($theme-colors, ash-darker);

      @include for-mobile {
        display: block;
        width: 100%;
      }
    }

    dd {
      display: table-cell;
      padding: 0.5rem;
      margin: 0;
      border: 1px solid map-get($theme-colors, ash-darker);
      vertical-align: middle;

      &:nth-of-type(2n - 1) {
        font-weight: bolder;
      }

      @include for-mobile {
        display: block;
        width: 70%;

        &:nth-of-type(2n - 1) {
          width: 30%;
        }
      }
    }
  }

  &__info {
    width: 40%;
    margin-left: 1rem;

    @include for-mobile {
      width: 100%;
      margin-left: 0;
    }

    & > ul {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 0;

      @include for-mobile {
        width: 100%;
        margin-bottom: 1rem;
      }

      li {
        display: flex;
        width: 50%;
        margin-bottom: 1rem;
        list-style: none;
        align-items: flex-start;

        @include for-mobile {
          flex-wrap: wrap;
          width: 50%;
        }

        &:first-child {
          width: 90%;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__label {
    width: 5em;
    flex-shrink: 0;
    padding: 0.2em;
    white-space: nowrap;
    font-weight: bolder;
    text-align: center;
    background-color: map-get($theme-colors, ash);
  }

  &__val {
    padding: 0.2em 0.5em;
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    & > * {
      flex-basis: 32%;
      margin-bottom: 1%;
    }
  }

  &__inquiry {
    display: flex;

    @include for-mobile {
      flex-wrap: wrap;
    }
  }

  &__tools {
    margin-right: 1rem;

    & > *:first-child {
      margin-bottom: 0.5rem;
    }

    @include for-mobile {
      width: 100%;
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }

  &__company {
    width: 100%;
    padding: 1rem;
    background-color: map-get($theme-colors, ash);
  }

  &__company-number {
    font-weight: bolder;
    font-size: 1.2rem;
    padding-right: 0.8rem;
  }
}
</style>
