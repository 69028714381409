<template>
  <div>
    <ul class="al">
      <li>
        <area-list-accordion
          region-name="北海道・東北"
          @set-pref="setSearchTarget"
          :prefs="
            prefArray([
              'hokkaido',
              'aomori',
              'iwate',
              'akita',
              'miyagi',
              'yamagata',
              'hukushima'
            ])
          "
        />
      </li>
      <li>
        <area-list-accordion
          region-name="甲信越"
          @set-pref="setSearchTarget"
          :prefs="prefArray(['yamanashi', 'nagano', 'niigata'])"
        />
      </li>
      <li>
        <area-list-accordion
          region-name="関東"
          @set-pref="setSearchTarget"
          :prefs="
            prefArray([
              'tokyo',
              'kanagawa',
              'saitama',
              'chiba',
              'ibaraki',
              'tochigi',
              'gunnma'
            ])
          "
        />
      </li>
      <li>
        <area-list-accordion
          region-name="北陸"
          @set-pref="setSearchTarget"
          :prefs="prefArray(['toyama', 'ishikawa', 'hukui'])"
        />
      </li>
      <li>
        <area-list-accordion
          region-name="東海"
          @set-pref="setSearchTarget"
          :prefs="prefArray(['aichi', 'gihu', 'mie', 'shizuoka'])"
        />
      </li>
      <li>
        <area-list-accordion
          region-name="近畿"
          @set-pref="setSearchTarget"
          :prefs="
            prefArray(['osaka', 'kyoto', 'hyogo', 'nara', 'shiga', 'wakayama'])
          "
        />
      </li>
      <li>
        <area-list-accordion
          region-name="中国"
          @set-pref="setSearchTarget"
          :prefs="
            prefArray([
              'tottori',
              'shimane',
              'okayama',
              'hiroshima',
              'yamaguchi'
            ])
          "
        />
      </li>
      <li>
        <area-list-accordion
          region-name="四国"
          @set-pref="setSearchTarget"
          :prefs="prefArray(['tokushima', 'kagawa', 'ehime', 'kochi'])"
        />
      </li>
      <li>
        <area-list-accordion
          region-name="九州・沖縄"
          @set-pref="setSearchTarget"
          :prefs="
            prefArray([
              'hukuoka',
              'saga',
              'nagasaki',
              'kumamoto',
              'oita',
              'miyazaki',
              'kagoshima',
              'okinawa'
            ])
          "
        />
      </li>
    </ul>

    <app-modal
      :show="showModal"
      @close="showModal = false"
      v-if="searchTargetPref"
    >
      <div class="text-center">
        <h3 class="mb-4">
          <i class="far fa-building"></i>
          物件を探す
        </h3>
        <p>選択したエリア</p>
        <h4 class="mb-4">{{ searchTargetPref.name }}</h4>
        <a :href="searchTargetPref.search_lines_path" class="btn btn-base m-2">
          <i class="fas fa-search"></i>
          路線・駅からの検索</a
        >
        <a :href="searchTargetPref.search_cities_path" class="btn btn-base m-2">
          <i class="fas fa-search"></i>
          市区町村からの検索</a
        >
      </div>
    </app-modal>
  </div>
</template>

<script>
import AppModal from "../common/AppModal";
import AreaListAccordion from "./AreaListAccordion";

export default {
  components: {
    AreaListAccordion,
    AppModal
  },
  data() {
    return {
      searchTargetPref: null,
      showModal: false
    };
  },
  props: {
    prefObj: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  watch: {
    searchTargetPref(val) {
      if (val) {
        this.showModal = true;
      }
    }
  },
  methods: {
    prefArray(prefNames = []) {
      return prefNames.map((prefName) => {
        return this.prefObj[prefName];
      });
    },
    setSearchTarget(prefObj) {
      this.searchTargetPref = prefObj;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../stylesheets/common/_common";

.al {
  list-style: none;
  padding-left: 0;
}
</style>
