import Vue from "vue";
import AppButtonRequest from "../components/common/AppButtonRequest";
import FixedMenu from "../components/common/AppFixedMenu";
import AreaMap from "../components/pc/AreaMap";
import AreaList from "../components/sp/AreaList";
import MansionSummary from "../components/pc/MansionSummary";
import mqMixin from "../mixins/mq";
import favAPI from "../api/favorite";

import dayjs from "dayjs";
Vue.prototype.$dayjs = dayjs;

new Vue({
  el: "#js-top-contents",
  mixins: [mqMixin],
  data: {
    prefectureObj: null,
    recentMansions: null,
    selectedMansions: [],
    favoritedRooms: []
  },
  components: {
    AppButtonRequest,
    AreaMap,
    AreaList,
    MansionSummary,
    FixedMenu
  },
  methods: {
    addSelectedMansions(event, ids, index) {
      this.$set(this.selectedMansions, index, ids);
    },
    getFavRooms() {
      return favAPI
        .index()
        .then((res) => {
          this.favoritedRooms = res.items;
        })
        .catch(() => {});
    }
  },
  computed: {
    selectedRoomIds() {
      // NOTE: APIに送る選択済みroom ids
      return this.selectedMansions.flat(1);
    },
    selectedMansionsLength() {
      return this.selectedMansions.filter((m) => {
        return m.length > 0;
      }).length;
    }
  },
  async created() {
    await this.getFavRooms();
  },
  mounted() {
    this.prefectureObj = JSON.parse(this.$el.getAttribute("data-prefecture"));
    this.recentMansions = JSON.parse(this.$el.getAttribute("data-mansions"));
    this.selectedMansions = Array(this.recentMansions.length);
  }
});
