<template>
  <div class="ala">
    <button class="ala__region-button" @click.prevent="toggleVisiblePrefs">
      <span class="ala__region-name">
        {{ regionName }}
      </span>
      <i class="fas fa-chevron-down"></i>
    </button>
    <ul class="ala__pref-list" v-if="visiblePrefs">
      <li v-for="pref in prefs" :key="pref.name">
        <button
          class="ala__pref-button"
          @click.prevent="$emit('set-pref', pref)"
        >
          {{ pref.name }}
          <i class="fas fa-chevron-right"></i>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    regionName: {
      type: String,
      default: ""
    },
    prefs: {
      type: Array
    }
  },
  data() {
    return {
      visiblePrefs: false
    };
  },
  methods: {
    toggleVisiblePrefs() {
      this.visiblePrefs = !this.visiblePrefs;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../stylesheets/common/_common";

.ala {
  list-style: none;
  padding-left: 0;

  &__region-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 1.2rem;
    font-weight: bolder;
    padding: 1rem 0.6em;
    appearance: none;
    border: 1px solid map-get($theme-colors, ash-darker);
    background-color: #fff;
  }

  &__region-name {
    padding-left: 0.6em;
    border-left: solid 8px map-get($theme-colors, base);
  }

  &__pref-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
    border-left: solid 1px map-get($theme-colors, ash-darker);
    border-right: solid 1px map-get($theme-colors, ash-darker);

    & > li {
      width: 50%;
      border-bottom: 1px solid map-get($theme-colors, ash-darker);

      &:nth-child(2n - 1) {
        border-right: 1px solid map-get($theme-colors, ash-darker);
      }
    }
  }

  &__pref-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 1.2rem;
    font-weight: bolder;
    padding: 1rem 0.6em;
    appearance: none;
    background-color: #fff;
    border: 0;
    color: map-get($theme-colors, base);
  }
}
</style>
