import API from "@/api/api";
import { APIPrefix } from "./base";

const dir = "favorites";

export default {
  index() {
    return API.get([APIPrefix, dir].join('/'))
  },
  create({ room_ids = [] }) {
    return API.jsonPost([APIPrefix, dir].join("/"), { room_ids });
  },
  bulk_delete({ room_ids = [] }) {
    return API.jsonPost([APIPrefix, dir, "bulk_delete"].join("/"), { room_ids });
  }
};
