<template>
  <a class="bc" :class="classObject" :href="link">
    <i class="far fa-copy"></i>
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    block: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      default: "#"
    }
  },
  computed: {
    classObject() {
      return {
        "bc--block": this.block
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../stylesheets/common/_common";

.bc {
  border-radius: 5px;
  padding: 0.6em 1em;
  background-color: #ffffff;
  font-weight: bolder;
  border: 1px solid map-get($theme-colors, ink);
  color: map-get($theme-colors, ink);
  text-align: center;

  &--block {
    width: 100%;
    display: block;
    white-space: nowrap;
  }

  &:hover {
    text-decoration: none;
  }
}
</style>
