export default {
  data() {
    return {
      viewMode: {
        pc: false,
        sp: false
      }
    };
  },
  methods: {
    _toggleViewMode(mql) {
      if (mql.matches) {
        this.viewMode.pc = false;
        this.viewMode.sp = true;
      } else {
        this.viewMode.pc = true;
        this.viewMode.sp = false;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      const mql = window.matchMedia("(max-width: 768px)");
      this._toggleViewMode(mql);
      mql.addListener(this._toggleViewMode);
    });
  }
};
