<template>
  <div v-if="mansion" class="ms" :class="{ 'ms--selected': checkedAllRooms }">
    <div class="ms__inner">
      <label class="ms__name">
        <input
          type="checkbox"
          v-if="checkable"
          v-model="checkedAllRooms"
          :disabled="disable && selectedRoomIds.length == 0"
        />
        {{ mansion.display_name }}
      </label>
      <div class="ms__campaigns">
        <div
          class="ms__campaign"
          v-for="campaign in mansion.public_campaigns"
          :key="campaign.id"
        >
          {{ campaign.name }}
        </div>
      </div>
      <div class="ms__detail">
        <div class="ms__head">
          <img
            :src="mansion.appearance_photo1.url"
            alt="動的にテキストを入れる"
            class="ms__pic"
          />
        </div>
        <div class="ms__body">
          <div class="ms__info">
            <ul>
              <li>
                <div class="ms__label">賃料</div>
                <div class="ms__val">
                  {{
                    mansion.daily_minimum_rent.toLocaleString()
                  }}〜/日 {{ mansion.monthly_minimum_rent.toLocaleString() }}〜/月
                </div>
              </li>
              <li>
                <div class="ms__label">構造</div>
                <div class="ms__val">{{ mansion.structure_type }}</div>
              </li>
              <li>
                <div class="ms__label">所在地</div>
                <div class="ms__val">
                  {{
                    mansion.prefecture +
                      mansion.city +
                      mansion.address1 +
                      mansion.display_address2
                  }}
                </div>
              </li>
              <li>
                <div class="ms__label">築年月</div>
                <div class="ms__val">
                  <p v-if="this.mansion.construction_date !== ''">
                    {{
                      $dayjs(this.mansion.construction_date).format(
                        "YYYY年MM月"
                      )
                    }}
                  </p>
                  <p v-else>不明</p>
                  <span v-if="reformText">（{{ reformText }}）</span>
                </div>
              </li>
              <li>
                <div class="ms__label">最寄駅</div>
                <div v-if="!stationEmpty" class="ms__val">
                  <p v-if="mansion.station1_name !== 'null' && mansion.station1_line !== 'null'">
                    {{
                      mansion.station1_line.split(" ")[0] +
                        " " +
                        mansion.station1_name
                    }}駅 徒歩{{ mansion.station1_minutes_walk }}分
                  </p>
                  <p v-if="mansion.station2_name !== 'null' && mansion.station2_line !== 'null'">
                    {{
                      mansion.station2_line.split(" ")[0] +
                        " " +
                        mansion.station2_name
                    }}駅 徒歩{{ mansion.station2_minutes_walk }}分
                  </p>
                  <p v-if="mansion.station3_name !== 'null' && mansion.station3_line !== 'null'">
                    {{
                      mansion.station3_line.split(" ")[0] +
                        " " +
                        mansion.station3_name
                    }}駅 徒歩{{ mansion.station3_minutes_walk }}分
                  </p>
                </div>
                <div v-else class="ms__val">
                  <p>なし</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="ms__notes">
        <p>{{ mansion.pr_text }}</p>
      </div>
    </div>

    <transition name="fh" mode="out-in">
      <div class="ms__rooms" v-if="visibleRooms">
        <room-summary
          v-for="room in selectableRooms"
          :key="room.id"
          :room.sync="room"
          :mansion="mansion"
          :operatingCompany="mansion.operating_company"
          :disable="disable && selectedRoomIds.length == 0"
          :contact-path="contactPath"
          :remove-fav="removeFavs"
          :checkable="checkable"
          :favorited-rooms="favoritedRooms"
          @add-favorite="$emit('add-favorite')"
        ></room-summary>
      </div>
    </transition>

    <button class="ms__toggle-button" @click.prevent="toggleVisibleRooms">
      <span v-if="visibleRooms">
        この物件の部屋情報を閉じる
        <i class="fas fa-chevron-up"></i>
      </span>
      <span v-else>
        この物件の部屋情報を見る
        <i class="fas fa-chevron-down"></i>
      </span>
    </button>
  </div>
</template>

<script>
import RoomSummary from "./RoomSummary";

export default {
  components: {
    RoomSummary
  },
  data() {
    return {
      visibleRooms: false,
      checkedAllRooms: false,
      selectableRooms: [],
      stationEmpty: false
    };
  },
  props: {
    mansion: {
      type: Object,
      default() {
        return null;
      }
    },
    disable: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number
    },
    contactPath: {
      type: String,
      default: ""
    },
    removeFavs: {
      type: Boolean,
      default: false
    },
    checkable: {
      type: Boolean,
      default: true
    },
    favoritedRooms: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    selectedRoomIds() {
      const selectedRooms = this.selectableRooms.filter((sr) => {
        return sr.selected;
      });

      return selectedRooms.map((e) => {
        return e.id;
      });
    },
    reformText() {
      if (this.mansion.reform.toLowerCase() === "done") return "リフォーム済み";
      return "";
    }
  },
  methods: {
    toggleVisibleRooms() {
      this.visibleRooms = !this.visibleRooms;
    }
  },
  watch: {
    checkedAllRooms(val) {
      if (val) {
        this.selectableRooms.forEach((room) => {
          room.selected = true;
        });
      } else {
        this.selectableRooms.forEach((room) => {
          room.selected = false;
        });
      }
    },
    selectableRooms: {
      deep: true,
      handler(sr) {
        this.$emit(
          "select-room",
          this.$event,
          this.selectedRoomIds,
          this.index
        );
      }
    },
    visibleRooms(val) {
      if (!val) {
        const ct = this.$el.getBoundingClientRect();
        const py = window.pageYOffset + ct.top;

        window.scrollTo({ top: py });
      }
    }
  },
  created() {
    let station_1 = false;
    let station_2 = false;
    let station_3 = false;
    if (
      this.mansion.station1_line == "null" ||
      this.mansion.station1_name == "null"
    ) {
      station_1 = true;
    }
    if (
      this.mansion.station2_line == "null" ||
      this.mansion.station2_name == "null"
    ) {
      station_2 = true;
    }
    if (
      this.mansion.station3_line == "null" ||
      this.mansion.station3_name == "null"
    ) {
      station_3 = true;
    }
    if (station_1 && station_2 && station_3) {
      this.stationEmpty = true; // 最寄り駅が全て設定されていないときは「なし」を表示
    }

    // NOTE: propsで受け取るVueで加工されたObjectをプレーンなObjectに変換しチェックボックス用に加工
    const rooms = this.mansion.public_rooms.map((r) => {
      r.selected = false;
      return r;
    });
    this.selectableRooms = JSON.parse(JSON.stringify(rooms));
  }
};
</script>

<style lang="scss" scoped>
@import "../../stylesheets/common/_common";

.ms {
  border: 1px solid map-get($theme-colors, ash-darker);
  margin-bottom: 2rem;

  &--selected {
    background-color: map-get($theme-colors, sky);
  }

  &__pic {
    max-width: 335px;

    @include for-mobile {
      width: 100%;
      max-width: 100%;
      margin-bottom: 1rem;
    }
  }

  &__name {
    font-weight: bolder;
    margin-bottom: 1rem;
  }

  &__campaigns {
    display: flex;

    @include for-mobile {
      flex-wrap: wrap;
    }
  }

  &__campaign {
    width: 33%;
    padding: 0.2rem 0.5rem;
    margin-bottom: 1rem;
    margin-right: 0.5rem;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    background-color: map-get($theme-colors, mint);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include for-mobile {
      width: 100%;
      margin-right: 0;
    }
  }

  &__inner {
    padding: 1.8rem;
    border-bottom: 1px solid map-get($theme-colors, ash-darker);
  }

  &__detail {
    display: flex;

    @include for-mobile {
      display: block;
    }
  }

  &__body {
    width: 100%;
    margin-left: 2rem;

    @include for-mobile {
      margin-left: 0;
    }
  }

  &__info {
    margin-bottom: 2rem;

    @include for-mobile {
      margin-bottom: 1rem;
    }

    & > ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-left: 0;
      margin-bottom: 0;

      @include for-mobile {
        display: block;
      }

      li {
        display: flex;
        width: 45%;
        margin-bottom: 1rem;
        list-style: none;
        align-items: flex-start;

        @include for-mobile {
          width: 100%;
        }

        &:last-child {
          width: 90%;
          margin-bottom: 0;
        }
      }
    }
  }

  &__label {
    width: 4em;
    flex-shrink: 0;
    padding: 0.2em;
    white-space: nowrap;
    font-weight: bolder;
    text-align: center;
    background-color: map-get($theme-colors, ash);
  }

  &__val {
    padding: 0.2em 0.5em;

    p {
      margin-bottom: 0;
    }
  }

  &__notes {
    margin: 30px 0 0 0;
    padding: 1rem;
    background-color: map-get($theme-colors, ash);
    white-space: pre-line;
    word-break: break-all;

    p {
      width: 100%;
      max-height: 7em;
      position: relative;
      margin: 0;
      line-height: 1.4;
      overflow: hidden;

      &:before,
      &:after {
        background-color: map-get($theme-colors, ash);
      }
      &:before {
        content: "...";
        position: absolute;
        top: 5.5em;
        right: 0;
      }
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
  }

  &__toggle-button {
    appearance: none;
    width: 100%;
    padding: 0.8rem 0;
    font-weight: bolder;
    color: #fff;
    background-color: #767676;
    border: 0;
  }
}

.fh-enter-active,
.fh-leave-active {
  transition: all 0.1s;
  opacity: 1;
  max-height: 500vh;
}
.fh-enter,
.fh-leave-to {
  opacity: 0;
  max-height: 0;
}
</style>
