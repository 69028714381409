<template>
  <div class="am" v-if="prefObj">
    <div class="am__area am__area--hokkaido">
      <area-map-pref
        :pref="prefObj.hokkaido"
        @set-pref="setSearchTarget"
        region="hokkaido"
      />
    </div>

    <div class="am__area am__area--tohoku">
      <area-map-pref
        :pref="prefObj.aomori"
        @set-pref="setSearchTarget"
        region="tohoku"
      />
      <area-map-pref
        :pref="prefObj.iwate"
        @set-pref="setSearchTarget"
        region="tohoku"
      />
      <area-map-pref
        :pref="prefObj.akita"
        @set-pref="setSearchTarget"
        region="tohoku"
      />
      <area-map-pref
        :pref="prefObj.miyagi"
        @set-pref="setSearchTarget"
        region="tohoku"
      />
      <area-map-pref
        :pref="prefObj.yamagata"
        @set-pref="setSearchTarget"
        region="tohoku"
      />
      <area-map-pref
        :pref="prefObj.hukushima"
        @set-pref="setSearchTarget"
        region="tohoku"
      />
    </div>

    <div class="am__area am__area--koshinetsu">
      <area-map-pref
        :pref="prefObj.toyama"
        @set-pref="setSearchTarget"
        region="koshinetsu"
      />
      <area-map-pref
        :pref="prefObj.niigata"
        @set-pref="setSearchTarget"
        region="koshinetsu"
      />
      <area-map-pref
        :pref="prefObj.ishikawa"
        @set-pref="setSearchTarget"
        region="koshinetsu"
      />
      <area-map-pref
        :pref="prefObj.nagano"
        @set-pref="setSearchTarget"
        region="koshinetsu"
      />
      <area-map-pref
        :pref="prefObj.hukui"
        @set-pref="setSearchTarget"
        region="koshinetsu"
      />
      <area-map-pref
        :pref="prefObj.yamanashi"
        @set-pref="setSearchTarget"
        region="koshinetsu"
      />
    </div>

    <div class="am__area am__area--kanto">
      <area-map-pref
        :pref="prefObj.gunnma"
        @set-pref="setSearchTarget"
        region="kanto"
      />
      <area-map-pref
        :pref="prefObj.tochigi"
        @set-pref="setSearchTarget"
        region="kanto"
      />
      <area-map-pref
        :pref="prefObj.saitama"
        @set-pref="setSearchTarget"
        region="kanto"
      />
      <area-map-pref
        :pref="prefObj.ibaraki"
        @set-pref="setSearchTarget"
        region="kanto"
      />
      <area-map-pref
        :pref="prefObj.tokyo"
        @set-pref="setSearchTarget"
        region="kanto"
      />
      <area-map-pref
        :pref="prefObj.chiba"
        @set-pref="setSearchTarget"
        region="kanto"
      />
      <area-map-pref
        :pref="prefObj.kanagawa"
        @set-pref="setSearchTarget"
        region="kanto"
      />
    </div>

    <div class="am__area am__area--chubu">
      <area-map-pref
        :pref="prefObj.gihu"
        @set-pref="setSearchTarget"
        region="chubu"
      />
      <area-map-pref
        :pref="prefObj.aichi"
        @set-pref="setSearchTarget"
        region="chubu"
      />
      <area-map-pref
        :pref="prefObj.shizuoka"
        @set-pref="setSearchTarget"
        region="chubu"
      />
      <area-map-pref
        :pref="prefObj.mie"
        @set-pref="setSearchTarget"
        region="chubu"
      />
    </div>

    <div class="am__area am__area--kansai">
      <area-map-pref
        :pref="prefObj.kyoto"
        @set-pref="setSearchTarget"
        region="kansai"
      />
      <area-map-pref
        :pref="prefObj.osaka"
        @set-pref="setSearchTarget"
        region="kansai"
      />
      <area-map-pref
        :pref="prefObj.hyogo"
        @set-pref="setSearchTarget"
        region="kansai"
      />
      <area-map-pref
        :pref="prefObj.nara"
        @set-pref="setSearchTarget"
        region="kansai"
      />
      <area-map-pref
        :pref="prefObj.shiga"
        @set-pref="setSearchTarget"
        region="kansai"
      />
      <area-map-pref
        :pref="prefObj.wakayama"
        @set-pref="setSearchTarget"
        region="kansai"
      />
    </div>

    <div class="am__area am__area--shikoku">
      <area-map-pref
        :pref="prefObj.ehime"
        @set-pref="setSearchTarget"
        region="shikoku"
      />
      <area-map-pref
        :pref="prefObj.kagawa"
        @set-pref="setSearchTarget"
        region="shikoku"
      />
      <area-map-pref
        :pref="prefObj.kochi"
        @set-pref="setSearchTarget"
        region="shikoku"
      />
      <area-map-pref
        :pref="prefObj.tokushima"
        @set-pref="setSearchTarget"
        region="shikoku"
      />
    </div>

    <div class="am__area am__area--chugoku">
      <area-map-pref
        :pref="prefObj.hiroshima"
        @set-pref="setSearchTarget"
        region="chugoku"
      />
      <area-map-pref
        :pref="prefObj.tottori"
        @set-pref="setSearchTarget"
        region="chugoku"
      />
      <area-map-pref
        :pref="prefObj.yamaguchi"
        @set-pref="setSearchTarget"
        region="chugoku"
      />
      <area-map-pref
        :pref="prefObj.okayama"
        @set-pref="setSearchTarget"
        region="chugoku"
      />
      <area-map-pref
        :pref="prefObj.shimane"
        @set-pref="setSearchTarget"
        region="chugoku"
      />
    </div>

    <div class="am__area am__area--kyushu">
      <area-map-pref
        :pref="prefObj.nagasaki"
        @set-pref="setSearchTarget"
        region="kyushu"
      />
      <area-map-pref
        :pref="prefObj.saga"
        @set-pref="setSearchTarget"
        region="kyushu"
      />
      <area-map-pref
        :pref="prefObj.hukuoka"
        @set-pref="setSearchTarget"
        region="kyushu"
      />
      <area-map-pref
        :pref="prefObj.oita"
        @set-pref="setSearchTarget"
        region="kyushu"
      />
      <area-map-pref
        :pref="prefObj.kumamoto"
        @set-pref="setSearchTarget"
        region="kyushu"
      />
      <area-map-pref
        :pref="prefObj.miyazaki"
        @set-pref="setSearchTarget"
        region="kyushu"
      />
      <area-map-pref
        :pref="prefObj.kagoshima"
        @set-pref="setSearchTarget"
        region="kyushu"
      />
    </div>

    <div class="am__area am__area--okinawa">
      <area-map-pref
        :pref="prefObj.okinawa"
        @set-pref="setSearchTarget"
        region="okinawa"
      />
    </div>

    <app-modal
      :show="showModal"
      @close="showModal = false"
      v-if="searchTargetPref"
    >
      <div class="text-center">
        <h3 class="mb-4">
          <i class="far fa-building"></i>
          物件を探す
        </h3>
        <p>選択したエリア</p>
        <h4 class="mb-4">{{ searchTargetPref.name }}</h4>
        <a :href="searchTargetPref.search_lines_path" class="btn btn-base">
          <i class="fas fa-search"></i>
          路線・駅からの検索</a
        >
        <a :href="searchTargetPref.search_cities_path" class="btn btn-base">
          <i class="fas fa-search"></i>
          市区町村からの検索</a
        >
      </div>
    </app-modal>
  </div>
</template>

<script>
import AppModal from "../common/AppModal";
import AreaMapPref from "./AreaMapPref";

export default {
  components: {
    AreaMapPref,
    AppModal
  },
  data() {
    return {
      searchTargetPref: null,
      showModal: false
    };
  },
  props: {
    prefObj: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  watch: {
    searchTargetPref(val) {
      if (val) {
        this.showModal = true;
      }
    },
    showModal(val) {
      if (!val) {
        this.searchTargetPref = null;
      }
    }
  },
  methods: {
    setSearchTarget(prefObj) {
      this.searchTargetPref = prefObj;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../stylesheets/common/_common";

@mixin col-1 {
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 0.4rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@mixin col-2 {
  max-width: 12em;
  display: flex;
  flex-wrap: wrap;

  & > * {
    max-width: 5.5em;
    flex-grow: 1;
    margin-bottom: 0.4rem;

    &:nth-child(2n) {
      margin-left: 0.4rem;
    }
  }
}

.am {
  position: relative;
  width: $max-content-width;
  height: 820px;
  margin: 0 auto;
  background-image: url("/images/monthly/pc/img_japan_map.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top center;

  &__area {
    position: absolute;

    &--hokkaido {
      top: 20%;
      right: 15%;
    }

    &--tohoku {
      top: 30%;
      right: 15%;

      @include col-2;
    }

    &--koshinetsu {
      top: 19%;
      left: 43%;

      @include col-2;
    }

    &--kanto {
      top: 52%;
      right: 19%;

      @include col-2;
    }

    &--chubu {
      bottom: 13%;
      left: 54%;

      @include col-1;
    }

    &--kansai {
      bottom: 0;
      left: 44%;

      @include col-1;
    }

    &--shikoku {
      bottom: 19%;
      left: 26%;

      @include col-2;
    }

    &--chugoku {
      top: 32%;
      left: 24%;

      @include col-2;
    }

    &--kyushu {
      bottom: 18%;
      left: 0;

      @include col-2;
    }

    &--okinawa {
      top: 28%;
      left: 8%;
    }
  }
}
</style>
