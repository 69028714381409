<template>
  <transition name="fade">
    <div v-if="isShown" class="app-modal" @click.self="closeModal">
      <div class="app-modal__frame">
        <div class="app-modal__control">
          <a class="app-modal__close" @click.self="closeModal">
            <i class="fa fa-times"></i>
            閉じる
          </a>
        </div>

        <div class="app-modal__contents">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShown: false
    };
  },
  watch: {
    show(newVal) {
      this.isShown = newVal;
    }
  },
  methods: {
    closeModal() {
      this.isShown = false;
      this.$emit("close");
    }
  },
  mounted() {
    this.isShown = this.show;
  }
};
</script>

<style lang="scss" scoped>
@import "../../stylesheets/common/_common";

.app-modal {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  @include for-mobile {
    padding: 0 0.5rem;
  }

  &__control {
    text-align: right;
  }

  &__close {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: map-get($theme-colors, base);
    color: #fff;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
      color: #fff;
    }
  }

  &__frame {
    max-width: 800px;
    width: 100%;
    background-color: #fff;
    margin: 1.75rem auto;
  }

  &__contents {
    padding: 1em 1em 3em;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
