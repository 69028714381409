<template>
  <div class="fixed-menu fm">
    <div class="fm__inner">
      <div class="fm__buttons">
        <button-fav
          v-if="removeFavsMode"
          @click.native="removeFavorites()"
          :active="true"
          >まとめてお気に入りから削除</button-fav
        >
        <button-fav v-else @click.native="addFavorites()"
          >まとめてお気に入りに追加</button-fav
        >
        <button-contact @click.native.prevent="handleBulkContact()"
          >まとめてお問い合わせ（無料）</button-contact
        >
      </div>
    </div>
  </div>
</template>

<script>
import ButtonFav from "./AppButtonFav";
import ButtonContact from "./AppButtonContact";
import favAPI from "../../api/favorite";

export default {
  components: {
    ButtonFav,
    ButtonContact
  },
  props: {
    selectedRoomIds: {
      type: Array,
      default() {
        return [];
      }
    },
    selectedMansionsLength: {
      type: Number,
      default: 0
    },
    removeFavsMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    contactUrl() {
      const path = "/contact/new";
      const query = this.selectedRoomIds
        .map((id) => {
          return "room_ids[]=" + id;
        })
        .join("&");
      return path + "?" + query;
    }
  },
  methods: {
    async addFavorites() {
      if (this.selectedRoomIds.length > 30) {
        alert("同時にお気に入りできるお部屋は30件までです。");
        return false;
      }

      await favAPI
        .create({ room_ids: this.selectedRoomIds })
        .then((res) => {
          alert("お気に入り追加しました");
          this.$emit("update-favorites");
        })
        .catch((e) => {
          alert(e["error_message"]);
          return;
        });
    },
    async removeFavorites() {
      await favAPI
        .bulk_delete({ room_ids: this.selectedRoomIds })
        .then((res) => {
          alert("お気に入りから削除しました");
          this.$emit("update-favorites");
        })
        .catch(() => {
          alert("エラーが発生しました。");
          return;
        });

      location.reload();
    },
    handleBulkContact() {
      if (this.selectedMansionsLength > 10) {
        alert("同時にお問い合わせできる物件は10件までです。");
      } else {
        location.href = this.contactUrl;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../stylesheets/common/_common";

.fixed-menu.fm {
  position: fixed;
  z-index: 10;
  width: 100%;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  $block: ".fm";

  #{$block} {
    &__inner {
      width: $max-content-width;
      margin: 0 auto;
      padding: 1rem 0;

      @include for-mobile {
        width: 100%;
      }
    }

    &__buttons {
      display: flex;
      justify-content: center;

      @include for-mobile {
        flex-wrap: wrap;
      }

      & > * {
        margin: 0 0.5rem 0;

        @include for-mobile {
          width: 100%;
          margin: 0 1rem 0;

          &:first-child {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
</style>
